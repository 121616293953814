@import "./variables.scss";


.object-container {

  align-items: center;
  height: 94vh;
  .object-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
