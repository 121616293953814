@import "./variables";
@import "./login";
@import "./register";
@import "./userManagement";
@import "./userCrud";
@import "./multiTenancy.scss";
@import "./properties.scss";
@import './propertyForm.scss';
@import './imageGridList.scss';
@import "./uploadPlan.scss";
@import "./object.scss";
@import "./filterBar.scss";
@import "./card.scss";
@import "./booking.scss";
@import "./details.scss";
@import "./detailsForm.scss";
@import "./leftMenu.scss";
@import "./appBar.scss";
@import "./reservation.scss";
@import "./clientsManagement.scss";
@import "./clientForm.scss";
@import "./registerSelection.scss";
@import "./settings.scss";

.App{
    overflow: hidden;
}
.main-header {
    color:$main-theme-color;
}
.user-info {
    height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Toastify__toast-container--top-right {
    top: $app-bar-height + 10px !important;
  }

a {
      cursor :pointer !important;
  }
  @media only screen and (max-width: 1500px) {
     .input-group{
        width: 67% !important ;
     } 
    .canvas1 {
        width: 67% !important ;
    }
  }

  .flatpickr-weekdays {
      background: $main-theme-color !important;
  }
  .flatpickr-months .flatpickr-month {
     background: $main-theme-color !important;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $main-theme-color !important;
  }
  .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background: $main-theme-color !important;
  }
  .flatpickr-day.selected {
    background: $main-theme-color !important;
    border-color: $main-theme-color !important;
  }
  span.flatpickr-weekday {
    background: $main-theme-color !important;
    color:#fff !important;
  }
  .flatpickr-calendar.arrowTop:after {
    border-bottom-color: $main-theme-color !important;
  }

  .selected-menu-item {
    font-weight: bold !important ;
    color: $main-theme-color !important;
  }

  .dh-tt-none{
    text-transform: none!important;
  }

  .recurrent-accordion-wrapper {
    &:not(.Mui-expanded) {
      .MuiAccordionSummary-content{
        cursor: not-allowed!important;
        opacity: 0.3;
      }
    }
    .MuiAccordionDetails-root {
      display: grid!important;
    }
    .btn-primary {
      background-color: $main-theme-color;
      border-color: $main-theme-color;
      &:hover {
        background-color: #ff0000;
        border-color: #ff0000
      }
    }
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
      background-color: #ff0000;
      font-weight: 800;
      border-color: #ff0000
    }
  }

  .dh-calendar .rbc-time-content {
    max-height: calc(100vh - #{$app-bar-height} - 150px);
    overflow-y: auto;
  }
