@import "./variables";

.reservation-canvas{

    width: 91%;
    overflow: auto;
    margin: $spacing auto;
    .konvajs-content{
        border-width: 2px;
        border-style: solid;
    }

}