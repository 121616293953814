
.main{
  
  .MuiDropzoneArea-root{
    height: 235px !important;
    width: 225px!important;
    
    min-height: 235px !important;
    margin-right: 7px !important;
    margin-left: 4px !important;
    margin-top: 3px !important;

    /* margin-top: 20 !important;
    margin-left:20 !important; */
  }
  .MuiCardContent-root {
    padding: 2;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .makeStyles-root-5 {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    height: 280px;
    width: 225px;
    margin-right: 5px;
    margin-left: 5px;
    place-content: center;
    justify-content: center;
    place-items: center;
  }
  
  .grid-schema {
    grid-template-columns: repeat(4, minmax(10vw, 15vw));
    
  }
  div.show-image {
    position: relative;
    float:left;
    margin:5px;
  }
  div.card img{
    width: max-content;
    height: max-content;
  }
  div.card:hover img{
    opacity:0.5;
  }
  div.card:hover input {
    display: block;
  }
  div.card input {
    position:absolute;
    display:none;
  }
  div.card input.update {
    top:0;
    left:0;
  }
  div.card input.delete {
    top:0;
    left:79%; 
  }
  div.card:hover Button {
    display: block;
  }
  div.card Button {
    position:absolute;
    display:none;
  }
  div.card Button.delete {
    top:3%;
    left:75%; 
  }

  
}

  