@import './variables.scss';

.card-root{
    margin:3*$spacing;
    max-width: 335px;
    .media-container{
        margin: 2*$spacing;
        .card-media{
            height: 250px;
            width: 100%;
        }
    };
    @media only screen and (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
    }
}
/* applying an ellipsis to multiline text */
.block-with-text {
    height: 60px;// 20 px per line
    text-overflow:ellipsis;
    overflow:hidden;
    // Addition lines for 3 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}