@import "variables";

.user-crud-container {
  margin: 4 * $spacing 8 * $spacing 4 * $spacing;
  display: flex;
  .wrapper {
    margin: 1 * $spacing;
    align-content: center;
  }
  .header {
    justify-content: flex-start;
    margin-bottom: 6 * $spacing;
    .user-header {
      color: lighten($color: grey, $amount: 10);
      letter-spacing: 0.1em;
    }
  }
  .button-group {
    .back-btn {
      background-color: lighten($color: grey, $amount: 40);
      text-transform: none;
      .back-icon {
        color: $main-theme-color;
      }
    }
    .edit-btn {
      background-color: lighten($color: grey, $amount: 40);
      text-transform: none;
      .edit-icon {
        margin-right: $spacing;
      }
    }
    .cancel-btn{
      text-transform: none;
      .cancel-icon {
        margin-right: $spacing;
      }
    }
  }
  .user-info-form {
    margin-top: 6*$spacing;
    width:85vw;
    align-self: center;
    .select-grid {
      width: 100%;
      justify-content: center;
      display: flex;
      .select {
          width: 35%;
      }
    }
    .first-row {
        flex-wrap: unset;
    }
    .mail-input {
      margin-bottom: 2 * $spacing;
    }
    .unset-left-padding{
      padding-left: 0px;
      margin-bottom:  2 * $spacing;
    }
    .radio-group{
        margin-top: 2*$spacing;
    }
    .user-status-grid{
      align-self: flex-end;
    }
  }
}
