//colors
$secondary:#DC004E;
$main-theme-color:#C61022;
$left-menu-color:#F2F2F2;
$left-menu-bg:#E7E7E7;
$app-bar-color:#ffffff;
$app-bar-text-color:#000000;

//spacings
$spacing:8px;
$footer-height:5vh;
$app-bar-height:88px;

//width
$drawerWidth:14vw;