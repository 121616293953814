@import "./variables";

.sign-in-wrapper {
   
    height: 100vh;
   
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;   
        .form {
            
            width: 65%; //Fix IE 11 Issue.
        }
        .submit {
            margin: 3*$spacing $spacing  2*$spacing;
            width: 40%;
            height: 5vh;
        }
}
.footer {
    height: $footer-height;
}
.float-left {
    float: left;
}
.float-right {
    float: right;
}
  