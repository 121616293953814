@import "variables";

.register-selection {
    height: calc(100vh - #{$app-bar-height});
    .paper {
       
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .register-tenant-btn {
        width: 100%;
    }
    .register-without-tenant-btn {
        width: 100%;
    }
   
} 