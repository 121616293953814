@import "./variables.scss";

.booking-wrapper {
  margin:  $spacing  2* $spacing;
  display: flex;
  
  .booking-container {
      margin: $spacing;
      align-content: center;
      .booking-table {
          margin-top:2 * $spacing;
          .row {
              cursor: pointer;
          }
      }
  }
}

.booking-header{
    color: #9a9a9a;
    letter-spacing: 0.1em;
}
