.rdtPicker {
  top: -200px;
}
.recurrent-accordion-wrapper .MuiAccordionDetails-root, .btn-group, .btn-group-vertical {
  display: block !important;
}

/*.App {*/
/*  !*text-align: center;*!*/
/*  overflow: visible;*/
/*  display: grid;*/
/*}*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 700px){
  .rbc-header {
    height: 60px;
    white-space: break-spaces;
  }
  .rbc-btn-group {
    display: grid;
  }
}