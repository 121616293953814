@import "variables";

.tenant-not-activated {
    height: calc(100vh - #{$app-bar-height});
    width: 40vw !important;
   
.not-activated-icon {
    height: 25vh !important;
    width: 25vw !important;
}
}
.tenant-not-found {
    display: flex;
    align-items: center;
    height: 75vh;
   
.not-activated-icon {
    height: 25vh !important;
    width: 25vw !important;
}
.button-grid {
    text-align: center;
}
.submit-btn {
    margin-top: 2*$spacing;
    margin-bottom: 2*$spacing;
    width: 15vw;
}
}