@import "./variables.scss";

.filter-bar-main {
  min-height: 10vh;
  max-height: 25vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  .filter-grid {
    justify-content: center;
  }
  .search-type{
    padding: 1*$spacing 1*$spacing;
  }
  .form-label-header {
    align-self: center;
    margin-right: 2 * $spacing;
  }
  .form-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
  }.date-time-picker{
      margin-left: $spacing;
      margin-right: $spacing;
      width: "15vw";
  }.form-label-rooms{
      align-self: center;
      margin: 0px 2*$spacing;
  }
}

.table-filter {
  .leftInputs {
    border:1px solid rgba(224, 224, 224, 1);
    padding: 5px;
  }
  .filterButton {
    border-radius: 0;
    padding: 5px;
    flex:1;
  }
}
