@import './variables.scss';

.upload-plan-container {  
    display: flex;
   //TODO Specify menu bar width position relative to variables for suıitable solution (Kaan)
    .upload-plan-form {
        padding: $spacing;
        width: 100%;
        .divider {
            margin: 4*$spacing  0 4*$spacing 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.25);
        }
        .dropzone-header {
            padding-bottom: 2*$spacing;
        }
        .MuiDropzoneArea-root {
            height: 32vh;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: rgba(0, 0, 0, 0.25);
        }
        .floor-image{
            padding: $spacing;
            width: 100%;
            .MuiDropzoneArea-root {
                height: 5vh;
                display: flex;
                align-items: center;
                justify-content: center;
                border-color: rgba(0, 0, 0, 0.25);
            }
            .dropzone-header {
                padding-bottom: 2*$spacing;
            }
            .divider {
                margin: 4*$spacing  0 4*$spacing 0;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.25);
            }
            
        }
        .delete-btn {
            margin-right: 1 * $spacing;
            text-transform: none;
            .delete-icon {
                margin-right: 1*$spacing;
            }
        }
    }
}