@import "./variables.scss";

.app-bar-wrapper{
    
    .app-bar {
        z-index: 1201;
        height: $app-bar-height;
        background-color: $app-bar-color !important;
        color: $app-bar-text-color !important;
        
    }
    .toolbar {
        justify-content: space-between;
    }

    .lang-select {
        background-color: $app-bar-color !important;
        color: $app-bar-text-color !important;
    }
    
    .title {
        .logo{
            max-width: 160px;
            max-height: 80px;
        } 
            cursor: pointer;
       
    }
    .navigation-bar {
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .avatar {
        background-color: #666666;
        color: $app-bar-color;
        cursor: pointer;
        border: 1px solid #000000;
        width: 5 * $spacing;
        height: 5*$spacing;
        font-size: 0.875rem;
        text-transform: uppercase;
    }   
    .nav-btn {
        text-transform: none;
    }
    .root {
        flex-grow: 1;
    }
    .menu-button {
        margin-right: 2 * $spacing;
    }
    .MuiInput-underline:after {
        display: none;
    }
    .MuiInput-underline:before {
        display: none;
    }
    .MuiSelect-icon{
        color: $app-bar-text-color !important;
    }
    .MuiInputBase-root{
        background-color: $app-bar-color !important;
        color: $app-bar-text-color !important;
        font-size: 0.875rem ;
    }
}