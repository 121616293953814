@import "variables.scss";


    .clients-form {
        margin: 4*$spacing;
        .client-header {
            color: lighten($color: grey, $amount: 10);
            letter-spacing: 0.1em;
        }
        .add-client-btn {
            background-color: lighten($color: grey, $amount: 40);
            text-transform: none;
            .add-icon {
                margin-right: $spacing;
            }
        }
        .clients-table{
            margin: 3 * $spacing;
        }
    }
