@import "variables";

.user-management-container {
  margin: 4 * $spacing 8 * $spacing 4 * $spacing;
  display: flex;
  .wrapper {
    margin: 1 * $spacing;
    align-content: center;
  }
  .header-items {
    justify-content: space-between;
    .user-header {
      color: lighten($color: grey, $amount: 10);
      letter-spacing: 0.1em;
    }
    .new-user-btn {
      background-color: lighten($color: grey, $amount: 40);
      text-transform: none;
    }
  }
  .users-table {
    margin-top: 2 * $spacing;
    .edit-icon {
      margin-left: $spacing;
    }
    .row {
      cursor: pointer;
    }
  }
}
