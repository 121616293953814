@import './variables.scss';
/* https://grid.layoutit.com/?id=EdWhKF5 */
.grid-container1 {
  display: grid;
  grid-template-columns: 15% 15% 42% 45%;
  grid-template-rows: 15vh 5vh 7vh 7vh 7vh;
  gap: 1vw 0.5vh;
  margin-left: 0;
  grid-template-areas: "master master master master" "container1_r1c1 container1_r1c1 . ." "container1_r2c1 container1_r2c1 . container1_r2c4" "container1_r3c1 container1_r3c2 . container1_r3c4" "container1_r4c1 container1_r4c1 . .";
}

.container1_r1c1 { grid-area: container1_r1c1; }

.container1_r2c1 { grid-area: container1_r2c1; }

.container1_r3c1 { grid-area: container1_r3c1; }

.container1_r3c2 { grid-area: container1_r3c2; }

.container1_r4c1 { grid-area: container1_r4c1; }

.container1_r2c4 { grid-area: container1_r2c4; }

.container1_r3c4 { grid-area: container1_r3c4; }

.master { grid-area: master; }

/* https://grid.layoutit.com/?id=r0Ducod */

.grid-container {
  display: grid;
  grid-template-columns: 19% 22% 22% 17% 40%;
  grid-template-rows: 5% 5% 5% 5% 5% 5% 5% 5%;
  gap: 14% 4%;
  grid-template-areas: "row1 row1 row1 . siderow1" "r1c1 r1c2 r1c3 . sider2" "r2c1 r2c2 r2c3 . sider3" "r3c1 r3c2 r3c3 . sider4" "r4c1 r4c2 r4c3 . sider4" "r5c1 r5c2 r5c3 . ." "r6c1 r6c2 r6c3 . ." "r7c1 r7c2 r7c3 . .";
}

.row1 { grid-area: row1; }

.siderow1 { grid-area: siderow1; }

.sider2 { grid-area: sider2; }

.r1c1 { grid-area: r1c1; }

.r1c3 { grid-area: r1c3; }

.r2c1 { grid-area: r2c1; }

.r3c1 { grid-area: r3c1; }

.r4c1 { grid-area: r4c1; }

.r5c1 { grid-area: r5c1; }

.r6c1 { grid-area: r6c1; }

.r7c1 { grid-area: r7c1; }

.r1c2 { grid-area: r1c2; }

.r2c2 { grid-area: r2c2; }

.r3c2 { grid-area: r3c2; }

.r4c2 { grid-area: r4c2; }

.r5c2 { grid-area: r5c2; }

.r6c2 { grid-area: r6c2; }

.r7c2 { grid-area: r7c2; }

.sider3 { grid-area: sider3; }

.sider4 { grid-area: sider4; }

.r2c3 { grid-area: r2c3; }

.r3c3 { grid-area: r3c3; }

.r4c3 { grid-area: r4c3; }

.r5c3 { grid-area: r5c3; }

.r6c3 { grid-area: r6c3; }

.r7c3 { grid-area: r7c3; }

// https://grid.layoutit.com/?id=EdWhKF5
.last-grid-container {
  display: grid;
  grid-template-columns: 15vh;
  grid-template-rows: 4vh 12vh;
  gap: 1vh 1vw;
  grid-template-areas: "first" "second";
}

.last-first { grid-area: last-first; }

.last-second { grid-area: last-second; }

.RichTextEditor__root___2QXK- {
    height: 250px;
}

.RichTextEditor__editor___1QqIU {
    height: 95%;
    overflow: auto;
}