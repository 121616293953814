@import "variables";

.clients-container {
    display: flex;
    justify-content: center;
    .clients-crud-form {
   
        margin: 4*$spacing;
       
        .client-header {
            color: lighten($color: grey, $amount: 10);
            letter-spacing: 0.1em;
            margin: $spacing 0 ;
        }
        .add-client-btn {
            background-color: lighten($color: grey, $amount: 40);
            text-transform: none;
            .add-icon {
                margin-right: $spacing;
            }
        }
        .clients-table{
            margin: 3 * $spacing;
        }

       .invite-container {
           margin-top: $spacing;
       }
        .invite-info-form {
            margin-top: 6*$spacing;
            width: 80vw;
            align-self: center;
            .select-grid {
              width: 100%;
              justify-content: center;
              display: flex;
              .select {
                  width: 35%;
              }
            }
            .first-row {
                flex-wrap: unset;
            }
            .mail-input {
              margin-bottom: 2 * $spacing;
            }
            .unset-left-padding{
              padding-left: 0px;
              margin-bottom:  2 * $spacing;
            }

            .invite-btn {
                background-color: lighten($color: grey, $amount: 40);
                text-transform: none;
                .add-icon {
                    margin-right: $spacing;
                }
            }
            .user-status-grid{
                align-self: flex-end;
              }
        }
    
}
}