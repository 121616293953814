// grid.layoutit.com?id=ieWZJrl
@import './variables.scss';

.details-grid-container {
    display: grid;
    grid-template-columns: 75vw 25vw;
    grid-template-rows: 5vh 15vh 17vh 17vh 15vh 14vh;
    gap: 2px 2px;
    grid-template-areas:
      "details-r1c1 details-r1c2"
      "details-image details-information"
      "details-image details-information"
      "details-image details-information"
      "details-r5c1 details-information"
      "details-r6c1 details-information";
    margin-left: 3vw;
    margin-top: 2vh;
  }
  
  .details-image { 
    grid-area: details-image; 
    place-content: center;
    margin-right: 7vw;
    .image-gallery-content .image-gallery-slide .image-gallery-image {
      max-height: 40vh;
      height: 40vh;
    }
    ////// use these 3 for image gallery
    .image-gallery {
      width: auto;
      height: 5vh;
      place-content: center;
      .image-gallery-svg{
        color: rgba(0, 0, 0, 0.4);
      }
      .image-gallery-bullets .image-gallery-bullet {
        &.active {
          background: $main-theme-color;
        }
      }
      .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
        background:$main-theme-color;
        transform: scale(1.1);
    }
    }

    .image-gallery-slide img {
      width: 60%;
      height: auto;
      // max-height: 20vh;
      object-fit: cover;
      overflow: hidden;
      object-position: center center;
      place-content: center;
    }
    /* .image-gallery-slide {
      transition: all 1200ms ease-out 1s !important;
    } */
  }

  .details{
    .slick-prev:before {
      color: $main-theme-color;
    }
    .slick-next:before {
      color: $main-theme-color;
    }
    .images {
    
     .slick-slide img {
      display: block;
      margin: 8px;
      height: 25vh;
      width: 25vh !important;
     }
    }
    .back-btn {
      background-color: lighten($color: grey, $amount: 40);
      text-transform: none;
      .back-icon {
        color: $main-theme-color;
      }
    }
    .floors{
      .reservation-btn{
        background-color: lighten($color: grey, $amount: 40);
        text-transform: none;
        .seat-icon {
          margin-right: 5px;
        }
      }
    }
    .no-suitable-message{
      display: flex;
      min-height:30vh;
      align-items: center;
      justify-content: center;
    }
  }

  //description and hygiene information area
.descriptions{
  .desc-header{
    margin: 2*$spacing 0px;
  }
}
    
    
  
  
  .details-r6c1 { grid-area: details-r6c1; }
  
  .details-r1c2 { grid-area: details-r1c2; }
  
  .details-r1c1 { grid-area: details-r1c1; }
  
  .details-information { grid-area: details-information;  }

  .hygiene-concept{
    margin-top: 2*$spacing;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 75%;
  }
