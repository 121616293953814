@import "variables.scss";

.menu-root {
    display:flex;
    .drawer{
        width: $drawerWidth;
        flex-shrink: 0;
        .paper{
            height: calc(100vh - #{$app-bar-height});
            width: $drawerWidth - 1vw;
            margin-top: $app-bar-height/2;
            background-color: $left-menu-color;
        }
    }
    .drawerContainer{
        overflow: auto;
        width: 90%;
        align-self: center;
        .list{
            background-color: $left-menu-bg;
            border-radius: $spacing;
            .list-item{
                padding-left: $spacing;
                display: flex;
                flex-direction: row;
                .new-floor-text{
                    padding-left: $spacing;
                }
            }
        }
        
    }
    .font-oswald {
        font-family: 'Oswald' !important;
        letter-spacing: 0.08071em !important;
    }
    .content{
        flex-grow: 1;
        padding: 3 * $spacing;
        margin: 3 * $spacing;
    }
    
}
