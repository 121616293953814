@import "./variables";


.sign-up-wrapper {
     height: calc(100vh - #{$app-bar-height});
     .paper-wrapper{
        height: calc(100vh - #{$app-bar-height});
        overflow: auto;
     }
     .paper {
        margin: 4*$spacing 3*$spacing;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        overflow-x: hidden;
        overflow-y: auto;

        .header-text {
            margin-bottom: $spacing;
            padding-inline-start:3px;
        }

    }
    .body-message {
        margin:10*$spacing 5*$spacing;
        color:black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85%;
    }
    .paper-login {
        margin: 2*$spacing ;
        height: calc(100vh - #{$app-bar-height});
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .form {
            margin: 4*$spacing auto;
            width: 75%; //Fix IE 11 Issue.
        }
    }
    .image {
        
        background-repeat   : no-repeat;
        background-color    : white;
        background-size     : cover;
        background-position : center;
    }
    .form {
        margin: 4*$spacing auto;
        width: 75%; //Fix IE 11 Issue.
        
    }
    .select {
        width: 100%;
    }
    .custom-subdomain {
        width: 100%;
    }
    .captcha-wrapper{
        justify-content: space-around !important;
        margin: 0 !important
    }
    .captcha {
        display: flex;
        margin: 0 auto;
    }
    .captcha-input {
      .label{
          font-size: 1em;
      }
       margin-left: $spacing;
    }
    .main-header {

        margin-bottom: 10*$spacing;
    }
    
     .submit {
        margin: 3*$spacing $spacing  2*$spacing;
        width: 40%;
        height: 5vh;
    }
    .register-disabled {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 95vh;
        font-size: 1vw;
       
    }
    .register-success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 95vh;
        font-size: 1.1vw;
        white-space: pre-line;
    }
    .disabled-icon {
        color: $main-theme-color;
        height: 20vh ;
        width: 20vw ;
    }
    .success-icon {
        color: green;
        height: 25vh ;
        width: 25vw ;
    }
}
.register-selection {
    height: calc(100vh - #{$app-bar-height});
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main-header-selection {

    text-align: start;
    align-items: center;
    
}

.register-tenant-btn {
    margin: 3*$spacing $spacing 0 3*$spacing !important;
    width: 100% !important ;
    height: 5vh;
    
}
.register-without-tenant-btn {
    margin: 3*$spacing $spacing  2*$spacing 3*$spacing !important;
    width: 100% !important;
    height: 5vh;
    color: #FF0000 !important;
    font-weight: bold !important;
}

.footer {
    height: $footer-height;
}
.float-left {
    float: left;
}
.float-right {
    float: left;
}
  