@import  "./variables.scss";

.Property.main{ 
    display: 'flex';
    flex-wrap: 'wrap';
    .Property-textField{
        margin-left: $spacing;
        margin-right: $spacing;
        width: '25ch';
    }
}   